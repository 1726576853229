<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FadeTransition',
});
</script>

<template>
  <transition
    name="fade"
    mode="out-in"
    :enter-active-class="$style['fade--enter-active']"
    :leave-active-class="$style['fade--leave-active']"
    :enter-from-class="$style['fade--enter-from']"
    :leave-to-class="$style['fade--leave-to']"
  >
    <!-- eslint-disable vue/require-toggle-inside-transition -->
    <slot />
    <!-- eslint-enable vue/require-toggle-inside-transition -->
  </transition>
</template>

<style lang="scss" module>
.fade {
  // stylelint-disable selector-class-pattern
  &--enter-active,
  &--leave-active {
    transition: opacity $transition-interaction;
  }

  &--enter-from,
  &--leave-to {
    opacity: 0;
  }
  // stylelint-enable selector-class-pattern
}
</style>
