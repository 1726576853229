<script lang="ts">
export const variants = [
  'primary', // $color-surface-primary
  'accent', // $color-surface-accent-primary;
];

export type Variant = ValueOf<typeof variants>;
</script>

<script setup lang="ts">
import IconCloseCircle from '~theme/assets/icons/outline/close-circle.svg?component';
import FadeTransition from '~theme/components/transitions/FadeTransition.vue';
import SlideTransition from '~theme/components/transitions/SlideTransition.vue';

const props = withDefaults(
  defineProps<{
    shown?: boolean;
    disableBackdrop?: boolean;
    noBackdrop?: boolean;
    noScroll?: boolean;
    closeable?: boolean;
    variant?: Variant;
  }>(),
  {
    shown: false,
    disableBackdrop: false,
    noBackdrop: false,
    noScroll: false,
    closeable: false,
    variant: 'primary',
  }
);

const emit = defineEmits<{
  (event: 'opened'): void;
  (event: 'close'): void;
  (event: 'closed'): void;
  (event: 'clickBackdrop'): void;
}>();

const onClickBackdrop = () => {
  emit('clickBackdrop');

  if (props.disableBackdrop) {
    return;
  }
  emit('close');
};

watch(
  () => props.shown,
  (value, oldValue) => {
    if (value && !oldValue) {
      emit('opened');
    }
    if (!value && oldValue) {
      emit('closed');
    }
  }
);

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <Teleport to="body">
    <FadeTransition v-if="noBackdrop">
      <div v-show="shown" :class="$style['modal--noBackdrop']">
        <slot />
      </div>
    </FadeTransition>
    <FadeTransition v-else>
      <div
        v-show="shown"
        :class="$style.modal"
        v-bind="$attrs"
        @click.self="onClickBackdrop"
      >
        <SlideTransition>
          <div
            v-if="shown"
            :class="[
              $style.modal__outer,
              {
                [$style['modal__outer--unscrollable']]: noScroll,
              },
            ]"
            @click.self="onClickBackdrop"
          >
            <div
              :class="[
                $style.modal__inner,
                $style[`modal__inner--${props.variant}`],
                {
                  [$style['modal__inner--unscrollable']]: noScroll,
                  [$style['modal__inner--closeable']]: closeable,
                },
              ]"
            >
              <slot />
            </div>
            <button
              v-if="closeable"
              :class="$style.modal__close"
              @click="$emit('close')"
            >
              <IconCloseCircle />
            </button>
          </div>
        </SlideTransition>
      </div>
    </FadeTransition>
  </Teleport>
</template>

<style lang="scss" module>
.modal {
  $gutter: 36px;

  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-surface-backdrop;
  z-index: 1;

  &__outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    // 画面が小さいときにモーダル自体がスクロールして全体を表示できるようにする
    overflow: scroll;
    // 画面が小さいときのスクロールの上下の余白 & 画面が大きいときにコンテンツがスクロールしないようにする余白
    padding: 36px 0;
    max-width: 100%;
    max-height: 100vh;

    &::-webkit-scrollbar {
      display: none;
    }

    &--unscrollable {
      overflow: hidden;
    }
  }

  &__inner {
    position: relative;
    max-width: calc(100vw - $gutter * 2);
    margin: $gutter;
    padding: 16px;
    background-color: $color-surface-primary;
    border-radius: $border-radius-rounder;
    box-shadow: $shadow-32;

    &--primary {
      background-color: $color-surface-primary;
    }

    &--accent {
      background-color: $color-surface-accent-primary;
    }

    &--unscrollable {
      height: calc(100vh - $gutter * 2);
    }

    &--closeable {
      margin-bottom: 0;
    }
  }

  &--noBackdrop {
    position: absolute;
    margin: auto;
  }

  &__close {
    width: 44px;
    height: 44px;
    font-size: 40px;
    color: $color-text-and-object-high-emphasis-inverse;

    &:nth-child(n + 2) {
      margin-top: 24px;
    }
  }
}
</style>
