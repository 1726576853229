import { useModalStateByKey } from './useModal/useEntireModalState';
import type { Component, FunctionalComponent, Ref } from 'vue';
import AppModal from '~theme/components/atoms/AppModal.vue';

type StateType = Ref<boolean>;
type OperationType = () => void;

type ModalName<T extends string> = `${Capitalize<T>}Modal`;
type StateKey<T extends string> = `is${ModalName<T>}Opened`;
type OpenKey<T extends string> = `open${ModalName<T>}`;
type CloseKey<T extends string> = `close${ModalName<T>}`;

const capitalize = <T extends string>(value: T): Capitalize<T> =>
  (value.slice(0, 1).toUpperCase() + value.slice(1)) as Capitalize<T>;

type DefaultKeys = {
  modalName: 'ControlledModal';
  stateKey: 'isModalOpened';
  openKey: 'openModal';
  closeKey: 'closeModal';
};
type NamedKeys<T extends string> = {
  modalName: ModalName<T>;
  stateKey: StateKey<T>;
  openKey: OpenKey<T>;
  closeKey: CloseKey<T>;
};

type Keys<T extends string> =
  | ModalName<T>
  | StateKey<T>
  | OpenKey<T>
  | CloseKey<T>;

type NamedReturnValue<L extends Keys<string>, ModalType extends Component> = {
  [K in keyof Record<L, unknown>]: K extends StateKey<string>
    ? StateType
    : K extends OpenKey<string>
      ? OperationType
      : K extends CloseKey<string>
        ? OperationType
        : K extends ModalName<string>
          ? ModalType
          : never;
};

const DEFAULT_KEYS: DefaultKeys = {
  modalName: 'ControlledModal',
  stateKey: 'isModalOpened',
  openKey: 'openModal',
  closeKey: 'closeModal',
};

const nameKeys = <ModalKey extends string>(
  modalKey: ModalKey | undefined
): typeof modalKey extends undefined ? DefaultKeys : NamedKeys<ModalKey> => {
  if (modalKey === undefined) {
    return DEFAULT_KEYS as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }

  const modalName = `${capitalize(modalKey)}Modal`;
  const stateKey = `is${modalName}Opened`;
  const openKey = `open${modalName}`;
  const closeKey = `close${modalName}`;

  return {
    modalName,
    stateKey,
    openKey,
    closeKey,
  } as any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

// useModalState
export const useModalState = (modalKey: string) => {
  const state = useModalStateByKey(modalKey);
  const isModalOpened = readonly(state);
  const openModal = () => {
    state.value = true;
  };
  const closeModal = () => {
    state.value = false;
  };

  return {
    isModalOpened,
    openModal,
    closeModal,
  };
};

// MARK: useModal
type ModalDefaultReturnValue<ModalType extends Component> = {
  ControlledModal: ModalType;
  openModal: OperationType;
  closeModal: OperationType;
};

export const useModalUnnamed = (
  modalKey?: string,
  modalComponent: Component = AppModal
): ModalDefaultReturnValue<Component> => {
  const autoGeneratedModalKey = useId();
  const { isModalOpened, openModal, closeModal } = useModalState(
    modalKey ?? autoGeneratedModalKey
  );

  const component: FunctionalComponent = (_, { attrs, slots }) => {
    const onClose = () => {
      closeModal();
      (attrs?.onClose as () => void)?.();
    };
    return h(
      modalComponent as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      { ...attrs, shown: isModalOpened.value, onClose },
      slots
    );
  };

  return {
    ControlledModal: component,
    openModal,
    closeModal,
  };
};

// MARK: useModalNamed
type ModalNamedReturnValue<
  T extends string,
  ModalType extends Component,
> = NamedReturnValue<ModalName<T> | OpenKey<T> | CloseKey<T>, ModalType>;

type UseModalNamed = {
  (): ModalDefaultReturnValue<typeof AppModal>;
  <Key extends string>(
    modalKey: Key
  ): ModalNamedReturnValue<Key, typeof AppModal>;
  <Key extends string, ModalType extends Component>(
    modalKey: Key,
    modalComponent: ModalType
  ): ModalNamedReturnValue<Key, ModalType>;
};

export const useModalNamed: UseModalNamed = <ModalKey extends string>(
  modalKey?: ModalKey,
  modalComponent: Component = AppModal
) => {
  const { ControlledModal, openModal, closeModal } = useModalUnnamed(
    modalKey,
    modalComponent
  );

  const { modalName, openKey, closeKey } = nameKeys(modalKey);

  return {
    [modalName]: ControlledModal,
    [openKey]: openModal,
    [closeKey]: closeModal,
  } as any; // eslint-disable-line @typescript-eslint/no-explicit-any
};
